import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
function We() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const { t } = useTranslation();
    return (
        <div className="withdraw px-[15px] py-[20px] min-h-[920px] bg-[#f2f2f2]">
            <div className="bg-[#fff] text-2xl rounded-2xl py-4 px-4 leading-10">
                <span>GIỚI THIỆU</span>
                <br />
                <br />
                <span>Trung Tâm Thương Mại CRESCENT MALL</span>
                <br />
                <br />
                <span>
                    Trung Tâm Thương Mại CRESCENT MALL là tập đoàn bán lẻ hàng đầu tại Thái Lan và là mảng kinh doanh
                    quan trọng nhất của Trung Tâm Thương Mại CRESCENT MALL. Tập đoàn sở hữu mạng lưới các thương hiệu
                    hàng đầu, cung cấp các sản phẩm đa lĩnh vực trên nhiều mô hình như cửa hàng bách hoá, cửa hàng tiện
                    lợi, cửa hàng đặc sản, siêu thị, đại siêu thị, trung tâm thương mại và mua sắm trực tuyến thông qua
                    các nền tảng đa kênh ở Thái Lan, Việt Nam và Ý. Hoạt động của Trung Tâm Thương Mại CRESCENT MALL bao
                    gồm bốn phân khúc: Thực phẩm, Thời trang, Điện tử – Gia dụng và Bất động sản.
                </span>
                <br />
                <br />
                <span>
                    <b>Các thương hiệu tiêu biểu</b>
                </span>
                <br />
                <span>Tập đoàn tập trung vào bốn phân phúc:</span>
                <br />
                <span>Thực phẩm, Thời trang, Điện tử - Gia dụng và Bất động sản</span>
                <br />
                <br />
                <span>
                    Để bắt kịp với nhu cầu, lối sống và kỳ vọng của người tiêu dùng đang thay đổi liên tục, Vạn Hạnh
                    Mall tập trung vào việc thiết kế trải nghiệm khách hàng xuyên suốt ở mọi điểm tiếp xúc. Bằng việc
                    xóa nhòa ranh giới giữa cửa hàng thực và cửa hàng ảo, mục tiêu của chúng tôi là trở thành nền tảng
                    đa kênh số 1, mang lại trải nghiệm mua sắm dễ dàng, thuận tiện và thú vị cho khách hàng ở mọi lúc,
                    mọi nơi.
                </span>
                <br />
                <br />
                <span>
                    Kết hợp giữa mua sắm truyền thống và thương mại điện tử, Trung Tâm Thương Mại CRESCENT MALL nâng cao
                    trải nghiệm đa kênh của khách hàng, đem đến cho khách hàng nhiều cách thức và lựa chọn mua sắm đa
                    dạng trên tất cả ngành hàng và nền tảng. Khách hàng có nhiều cách để mua sắm bất cứ thứ gì, ở bất cứ
                    nơi đâu và bất cứ lúc nào thông qua các kênh liền mạch như Mua sắm trực tuyến hoặc Ứng dụng dành cho
                    thiết bị di động, giúp trải nghiệm mua sắm trở nên dễ dàng và thú vị hơn ngay trong tầm tay, hoặc
                    trải nghiệm cá nhân với các kênh độc đáo như Mua sắm qua Facebook, Gọi & Mua và Trò chuyện & Mua, và
                    thậm chí chọn thu tiền mua hàng một cách thuận tiện cho dù đó là Nhấp & Thu thập hoặc Mua sắm & Lái
                    xe.
                </span>
                <br />
                <br />
                <span>
                    Với thôi thúc cải tiến từng ngày, Trung Tâm Thương Mại CRESCENT MALL cam kết thúc đẩy sự phát triển
                    của lối sống và thói quen mua sắm của người tiêu dùng bằng cách giới thiệu không ngừng các hình thức
                    bán lẻ mới phục vụ nhu cầu đa dạng và tạo ra những trải nghiệm đặc biệt vượt trội.
                </span>
                {/*
                <br />
                <br />
                <span>
                    Với thông điệp chủ đạo "Mỗi trải nghiệm, mỗi niềm vui", GIGAMALL không chi mang đến trải nghiệm đa
                    dạng và tiện ích phong phú cho khách mua sắm, mà còn có không gian vui chơi thân thiện cho trẻ em,
                    nơi thư giãn giải trí cho cả nhà.
                </span>
                <br />
                <br />
                <span>
                    Để mang lại trải nghiệm mới mẻ cho khách hàng, GIGAMALL tiên phong ứng dụng công nghệ vào các hoạt
                    động dịch vụ và giải trí. Ngay từ sảnh chính tòa nhà, khách tham quan có thể chiêm ngưỡng dàn đèn
                    LED chuyển màu độc đáo kết hợp với sàn tương tác LED hiện đại. Dàn đèn với tên gọi Di.
                </span>
                <br />
                <br />
                <span>
                    Crystal và sàn LED tương tác này là điểm nhấn công nghệ độc đáo, lần đầu tiên có mặt tại Việt Nam,
                    mang đến những màn trình diễn âm thanh kết hợp ánh sáng mãn nhãn cho người xem.
                </span> */}
                {/* <br />
                <br />
                <span>{t('content.we.8')}</span>
                <br />
                <br />
                <span>{t('content.we.9')}</span>
                <br />
                <br />
                <span>{t('content.we.10')}</span>
                <br />
                <br />
                <span>{t('content.we.11')}</span>
                <br />
                <br />
                <span>{t('content.we.12')}</span>
                <br />
                <br />
                <span>{t('content.we.13')}</span> */}
            </div>
        </div>
    );
}

export default We;
