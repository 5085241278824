import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
function ChinhSachBM() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const { t } = useTranslation();
    return (
        <div className="withdraw px-[15px] py-[20px] min-h-[920px] bg-[#f2f2f2]">
            <div className="bg-[#fff] text-2xl rounded-2xl py-4 px-4 leading-10">
                <span>
                    <b>Chính sách</b>
                </span>
                <br />
                <span>
                    <b>Bảo mật thông tin</b>
                </span>
                <br />
                <span>Dữ liệu cá nhân nào chúng tôi thu thập và lý do chúng tôi thu thập dữ liệu đó</span>
                <br />
                <br />
                <span>
                    <b>1. Bình luận:</b>
                </span>
                <br />
                <span>
                    Khi khách truy cập để lại nhận xét trên trang web, chúng tôi sẽ thu thập dữ liệu hiển thị trong biểu
                    mẫu nhận xét cũng như địa chỉ IP của khách truy cập và chuỗi tác nhân người dùng trình duyệt để giúp
                    phát hiện thư rác.
                </span>
                <br />
                <br />
                <span>
                    Một chuỗi ẩn danh được tạo từ địa chỉ email của bạn (còn gọi là hàm băm) có thể được cung cấp cho
                    dịch vụ Gravatar để xem bạn có đang sử dụng nó hay không. Chính sách quyền riêng tư của dịch vụ
                    Gravatar có tại đây. Sau khi phê duyệt bình luận của bạn, ảnh hồ sơ của bạn sẽ được hiển thị công
                    khai trong bối cảnh bình luận của bạn.
                </span>
                <br />
                <br />
                <span>
                    <b>2. Phương tiện truyền thông:</b>
                </span>
                <br />
                <span>
                    Nếu bạn tải hình ảnh lên trang web, bạn nên tránh tải lên hình ảnh có chứa dữ liệu vị trí được nhúng
                    (EXIF GPS). Khách truy cập vào trang web có thể tải xuống và trích xuất bất kỳ dữ liệu vị trí nào từ
                    hình ảnh trên trang web.
                </span>
                <br />
                <br />
                <span>
                    <b>3. Biểu mẫu liên hệ:</b>
                </span>
                <br />
                <span>
                    <b>* Bánh quy</b>
                </span>
                <br />
                <span>
                    Nếu bạn để lại nhận xét trên trang web của chúng tôi, bạn có thể chọn tham gia lưu tên, địa chỉ
                    email và trang web của mình vào cookie. Những điều này nhằm mục đích thuận tiện cho bạn để bạn không
                    phải điền lại thông tin chi tiết của mình khi để lại nhận xét khác. Những cookie này sẽ tồn tại
                    trong một năm.
                </span>
                <br />
                <br />
                <span>
                    Nếu bạn truy cập trang đăng nhập của chúng tôi, chúng tôi sẽ đặt cookie tạm thời để xác định xem
                    trình duyệt của bạn có chấp nhận cookie hay không. Cookie này không chứa dữ liệu cá nhân và bị loại
                    bỏ khi bạn đóng trình duyệt.
                </span>
                <br />
                <br />
                <span>
                    Khi bạn đăng nhập, chúng tôi cũng sẽ thiết lập một số cookie để lưu thông tin đăng nhập và các lựa
                    chọn hiển thị trên màn hình của bạn. Cookie đăng nhập tồn tại trong hai ngày và cookie tùy chọn màn
                    hình tồn tại trong một năm. Nếu bạn chọn “Ghi nhớ tôi”, thông tin đăng nhập của bạn sẽ tồn tại trong
                    hai tuần. Nếu bạn đăng xuất khỏi tài khoản của mình, cookie đăng nhập sẽ bị xóa.
                </span>
                <br />
                <br />
                <span>
                    Nếu bạn chỉnh sửa hoặc xuất bản một bài viết, một cookie bổ sung sẽ được lưu trong trình duyệt của
                    bạn. Cookie này không bao gồm dữ liệu cá nhân và chỉ cho biết ID bài đăng của bài viết bạn vừa chỉnh
                    sửa. Nó hết hạn sau 1 ngày.
                </span>
                <br />
                <br />
                <span>
                    <b>4. Nội dung được nhúng từ các trang web khác</b>
                </span>
                <br />
                <span>
                    Các bài viết trên trang này có thể bao gồm nội dung được nhúng (ví dụ: video, hình ảnh, bài viết,
                    v.v.). Nội dung được nhúng từ các trang web khác hoạt động theo cách giống hệt như khi khách truy
                    cập đã truy cập trang web đó.
                </span>
                <br />
                <span>
                    Các trang web này có thể thu thập dữ liệu về bạn, sử dụng cookie, nhúng theo dõi bổ sung của bên thứ
                    ba và giám sát sự tương tác của bạn với nội dung được nhúng đó, bao gồm theo dõi tương tác của bạn
                    với nội dung được nhúng nếu bạn có tài khoản và đăng nhập vào trang web đó.
                </span>
                <br />
                <br />
                <span>
                    <b>5. Phân tích</b>
                </span>
                <br />
                <span>
                    <b>* Chúng tôi chia sẻ dữ liệu của bạn với ai</b>
                </span>
                <br />
                <span>Nếu bạn yêu cầu đặt lại mật khẩu, địa chỉ IP của bạn sẽ được bao gồm trong email đặt lại.</span>
                <br />
                <br />
                <span>
                    <b>* Chúng tôi lưu giữ dữ liệu của bạn trong bao lâu</b>
                </span>
                <br />
                <span>
                    Nếu bạn để lại nhận xét, nhận xét đó và siêu dữ liệu của nó sẽ được giữ lại vô thời hạn. Điều này là
                    để chúng tôi có thể tự động nhận ra và phê duyệt mọi nhận xét tiếp theo thay vì giữ chúng trong hàng
                    đợi kiểm duyệt.
                </span>
                <br />
                <span>
                    Đối với người dùng đăng ký trên trang web của chúng tôi (nếu có), chúng tôi cũng lưu trữ thông tin
                    cá nhân họ cung cấp trong hồ sơ người dùng của họ. Tất cả người dùng có thể xem, chỉnh sửa hoặc xóa
                    thông tin cá nhân của mình bất kỳ lúc nào (ngoại trừ việc họ không thể thay đổi tên người dùng của
                    mình). Quản trị viên trang web cũng có thể xem và chỉnh sửa thông tin đó.
                </span>
                <br />
                <br />
                <span>
                    <b>* Bạn có quyền gì đối với dữ liệu của mình</b>
                </span>
                <br />
                <span>
                    Nếu bạn có tài khoản trên trang web này hoặc đã để lại nhận xét, bạn có thể yêu cầu nhận tệp xuất dữ
                    liệu cá nhân mà chúng tôi lưu giữ về bạn, bao gồm mọi dữ liệu bạn đã cung cấp cho chúng tôi. Bạn
                    cũng có thể yêu cầu chúng tôi xóa mọi dữ liệu cá nhân mà chúng tôi lưu giữ về bạn. Điều này không
                    bao gồm bất kỳ dữ liệu nào chúng tôi có nghĩa vụ lưu giữ cho mục đích hành chính, pháp lý hoặc bảo
                    mật.
                </span>
                <br />
                <br />
                <span>
                    <b>* Nơi chúng tôi gửi dữ liệu của bạn</b>
                </span>
                <br />
                <span>
                    Nhận xét của khách truy cập có thể được kiểm tra thông qua dịch vụ phát hiện thư rác tự động.
                </span>
            </div>
        </div>
    );
}

export default ChinhSachBM;
